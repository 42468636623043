import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiMoreHorizontal } from "react-icons/fi";
import { Dropdown } from 'react-bootstrap';
import ShiftEditModal from 'components/Shifts/ShiftEditModal';
import { showModal, hideModal } from "actions/modalActions";
import * as shiftsActions from 'actions/shiftsActions';
import { shiftsService } from "services";
import { errorFormating } from "utils/utils";

class ShiftList extends Component {
	constructor(props) {
		super(props);
		// console.log(props);

		this.closeModal = this.closeModal.bind(this);
		this.openEditShiftModal = this.openEditShiftModal.bind(this);
		this.openDeleteShiftModal = this.openDeleteShiftModal.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
	}

	closeModal() {
		this.props.hideModal()
	}

	openEditShiftModal(shift) {
		const { getShifts } = this.props;

		this.props.showModal({
			open: true,
			title: 'Editar turno',
			style: { width: '550px' },
			content: <ShiftEditModal shift={shift} getShifts={getShifts} handleClose={this.closeModal} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openDeleteShiftModal(idShift) {
		this.props.showModal({
			open: true,
			title: 'Eliminar turno',
			style: { width: '400px' },
			message: '¿Desea eliminar el turno? Esta acción no se puede deshacer',
			deleteAction: () => this.confirmDelete(idShift),
			closeModal: this.closeModal
		}, 'delete')
	}

	openAlertModal(message) {
		this.props.showModal({
			open: true,
			title: "Error al eliminar",
			style: { width: '400px' },
			message: message,
			closeModal: this.closeModal
		}, 'alert')
	}

	confirmDelete = async (idShift) => {
		const response = await shiftsService.remove(idShift);

		if (!response.ok) {
			let textError = errorFormating(response);
			this.openAlertModal(textError.id);
		}
		else {

			this.props.getShifts();

			this.closeModal();
		}
	}


	getEmptyRow = () => {
		return (
			<p>Sin resultados.</p>
		);
	}

	orderDayTypes = (shift_day_types) => {
		// console.log(shift_day_types);
		return shift_day_types.sort(function (a, b) {

			// fix para poner festivos al final
			if (!a.weekday)
				a.weekday = 8;
			if (!b.weekday)
				b.weekday = 8;

			return a.weekday - b.weekday;
		});
	};

	getDayTypes = (type_list) => type_list.map((item) => {
		return (
			<li key={item.id} aria-label={item.description} title={item.description} className="tag-default tag-comma" style={{ backgroundColor: item.background_color && item.background_color }}>
				{item.name}
			</li>
		)
	});

	getShiftIntervals = (time_intervals, color) =>
		time_intervals.map((item, i) => {
			// console.log(item);
			const start = item.start.replace(":00", "");
			const end = item.end.replace(":00", "");
			return (
				<li key={i} className="time-interval">
					{start}-{end}h
				</li>
			);
		});

	getRows = () => this.props.shifts.map((shift) => {

		const shift_day_types = this.orderDayTypes(shift.day_types);
		// console.log({ shift_day_types });

		return (
			<div className="col-sm-6 col-lg-4 col-xl-3" key={shift.id}>

				<article className="card" key={shift.id}>
					<div className="card-top">
						<h2
							className="card-title card-title-colored"
							style={{ backgroundColor: shift.background_color && shift.background_color, borderColor: shift.border_color && shift.border_color }}>
							{shift.name}
						</h2>
					</div>
					<div className="card-content">
						<span className="shift-description">{shift.description}</span>

						<div className="form-group no-form">
							<label>Tipos de día</label>
							<ul className="list-tags">
								{this.getDayTypes(shift_day_types)}
							</ul>
						</div>

						<div className="form-group no-form">
							<label>Tipo de turno</label>
							<span>{shift.type}</span>
						</div>
						<div className="form-group form-group-periodos no-form">
							<label>Periodos</label>
							{shift.time_intervals !== undefined && shift.time_intervals.length > 0 ?
								<ul className="time-intervals">
									{this.getShiftIntervals(shift.time_intervals)}
								</ul>
								: <span className='empty'>-</span>
							}
						</div>
					</div>

					<div className="card-actions bottom-right">
						<Dropdown>
							<Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
							<Dropdown.Menu alignRight>
								<Dropdown.Item as="button" onClick={() => this.openEditShiftModal(shift)}>Editar</Dropdown.Item>
								{
									!shift.system && (<Dropdown.Item as="button" onClick={() => this.openDeleteShiftModal(shift.id)}>Eliminar</Dropdown.Item>)
								}
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</article>

			</div>
		)
	});

	render() {
		return (
			<>
				{this.props.shifts.length === 0 ?
					this.getEmptyRow()
					:
					<div className="row list-shifts">
						{this.getRows()}
					</div>
				}
			</>
		)
	}
}


const mapStateToProps = (reducers) => {
	return reducers.shiftsReducer;
};

const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }))
	},
	getAllShifts: (options) => dispatch(shiftsActions.getAllShifts(options)),
})


export default connect(mapStateToProps, mapDispatchToProps)(ShiftList)