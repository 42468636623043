import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { FiCalendar, FiX } from "react-icons/fi";
import { FormGroup, FormControl, FormLabel, Row, Col } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es"; // datepicker locale
registerLocale("es", es);

import { transactionsService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import * as rulesActions from 'actions/rulesActions';

class TransactionEdit extends React.Component {

	constructor(props) {
		super(props);
		// console.log(props);

		let transaction = {
			day: '',
			employee_id: '',
			account_id: '',
			observations: ''
		}

		let isEdit = false;
		let isReadOnly = false;

		if (props.transaction) {
			isEdit = true;
			transaction = props.transaction;
			transaction.employee_id = transaction.employee.id;
			transaction.account_id = transaction.account.id;
			isReadOnly = (transaction.type !== 'Manual') ? true : false
		}

		this.state = {
			isReadOnly,
			isEdit,
			isLoading: false,
			submitted: false,
			errors: {},
			transaction,
			new_item_errors: {},
			temp_employee: {},
			selected_employees: [],
		};
	}

	handleChange = event => {
		const { transaction } = this.state;

		let key = event.target.id;
		let value = event.target.value;

		this.setState({
			transaction: {
				...transaction,
				[key]: value
			},
		});
	}

	handleAddChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	}

	handleDateChange = (id, date) => {
		const { transaction } = this.state;

		let key = id;
		let value = date ? moment(date).format("YYYY-MM-DD") : null;

		this.setState({
			transaction: {
				...transaction,
				[key]: value,
			},
		});
	};

	checkValidField = (name) => {
		return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
	}


	handleSubmit = async event => {
		event.preventDefault();

		this.setState({ isLoading: true });
		let response = '';
		const props = this.props;
		const { transaction, temp_employee, selected_employees } = this.state;

		// if edit => update; else => new
		if (this.state.isEdit) {
			response = await transactionsService.update(transaction);
		}
		else if (selected_employees.length > 0) {
			// array de transacciones para cada empleado seleccionado
			const transactions = [];
			selected_employees.map((se) => {
				var temp_transaction = { ...transaction };
				temp_transaction.employee_id = se.id;
				transactions.push(temp_transaction);
				return true;
			});

			response = await transactionsService.addMultiple(transactions);
		}
		else {
			if (transaction.employee_id === "" && temp_employee !== undefined)
				transaction.employee_id = temp_employee;
			response = await transactionsService.add(transaction);
		}

		//console.log(response);
		if (response.ok) {
			this.setState({
				isLoading: false,
				errors: {},
			}, () => {

				toastr.success('¡Bien!', 'Cambios guardados correctamente');
				this.props.getTransactions();
				props.handleClose();

			});
		}
		else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response)
			});
		}

	}


	// employees
	getSelectedEmployees = () => this.state.selected_employees.map((item) => {
		return (
			<li key={item.id} className="tag-default tag-delete" style={{ backgroundColor: item.background_color && item.background_color }}>
				{item.name} {item.surname}
				<button type="button" onClick={() => this.removeEmployee(item.id)} className="btn-tag-delete btn-transparent"><FiX /></button>
			</li>
		)
	});

	addEmployee = () => {
		let { selected_employees, temp_employee } = this.state;
		let isValid = true;
		let employee;
		const new_item_errors = {};

		if (temp_employee === undefined) {
			isValid = false;
		}
		else {
			employee = this.props.select_employees.find(item => item.id === parseInt(temp_employee));
			if (employee === undefined) {
				isValid = false;
				new_item_errors.employee = false;
			}
		}

		if (isValid) {
			// check if day already exists
			const exists = selected_employees.find(item => item.id === parseInt(temp_employee));

			if (!exists) {
				selected_employees = selected_employees.concat(employee);
			}

			this.setState({
				selected_employees,
				new_item_errors: {},
				temp_employee: {},
			});
		}
		else {
			this.setState({
				new_item_errors,
			});
		}
	}
	removeEmployee = (idEmployee) => {
		this.setState((prevState, props) => {
			return {
				selected_employees: prevState.selected_employees.filter(item => item.id !== parseInt(idEmployee))
			};
		});
	}

	render() {
		const { isReadOnly, transaction, errors, isEdit, temp_employee, new_item_errors } = this.state;
		const { select_accounts, select_employees } = this.props;

		const optionEmployeeList = select_employees.length > 0 && select_employees.map((item, i) => {
			return (
				<option key={i} value={item.id}>{item.name} {item.surname}</option>
			)
		}, this);

		const optionAccountList = select_accounts.length > 0 && select_accounts.map((item, i) => {
			return (
				<option key={i} value={item.id}>{item.name}</option>
			)
		}, this);

		return (
			<form className="modal-transaction" >
				<div className="modal-body">

					{isEdit ?
						<FormGroup controlId="employee_id">
							<FormLabel>Empleado <span className="label-required">*</span></FormLabel>
							<FormControl
								type="text"
								value={transaction.employee_id}
								onChange={this.handleChange}
								placeholder="Selecciona empleado"
								as="select"
								isInvalid={this.checkValidField('employee_id')}
								disabled={isReadOnly}
							>
								<option value="">Selecciona empleado</option>
								{optionEmployeeList}
							</FormControl>

							<FormControl.Feedback type="invalid">
								{errors.employee_id}
							</FormControl.Feedback>
						</FormGroup>
						:
						<div
							className="form-group mb20"
						>
							<p className="mb5 label">Selecciona uno o varios empleados</p>

							<div className="flex-group mb10">
								<FormGroup controlId="temp_employee">
									<FormControl
										type="text"
										value={temp_employee}
										placeholder="Selecciona el empleado"
										onChange={this.handleAddChange}
										isInvalid={new_item_errors.employee !== undefined}
										as="select"
									>
										<option value="">Selecciona el empleado</option>
										{optionEmployeeList}
									</FormControl>
								</FormGroup>
								<button type="button" onClick={this.addEmployee} className="btn btn-primary">Añadir</button>
							</div>
							<ul className="list-tags">
								{this.getSelectedEmployees()}
							</ul>
						</div>
					}


					<FormGroup controlId="account_id">
						<FormLabel>Cuenta <span className="label-required">*</span></FormLabel>
						<FormControl
							value={transaction.account_id}
							onChange={this.handleChange}
							placeholder="Selecciona cuenta"
							isInvalid={this.checkValidField('account_id')}
							as="select"
							disabled={isReadOnly}
						>
							<option value="">Selecciona cuenta</option>
							{optionAccountList}
						</FormControl>

						<FormControl.Feedback type="invalid">
							{errors.account_id}
						</FormControl.Feedback>
					</FormGroup>

					<Row>
						<Col>
							<FormGroup controlId="day" className={`form-date ${(transaction.day === '' || !transaction.day) && 'empty'}  ${this.checkValidField('day') && "is-invalid"}`}>
								<FormLabel>Fecha <span className="label-required">*</span></FormLabel>

								<DatePicker
									selected={transaction.day === "" || !transaction.day ? transaction.day : moment(transaction.day).toDate()}
									onChange={(date) => this.handleDateChange("day", date)}
									isInvalid={this.checkValidField("day")}
									className="form-control"
									dateFormat="dd/MM/yyyy"
									locale='es'
									placeholderText="dd/mm/aaaa"
									disabled={isReadOnly}
								/>

								<FiCalendar />
								<FormControl.Feedback type="invalid">
									{this.state.errors.day}
								</FormControl.Feedback>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup controlId="value">
								<FormLabel>Valor <span className="label-required">*</span></FormLabel>
								<FormControl
									type="text"
									value={transaction.value}
									onChange={this.handleChange}
									placeholder="Valor"
									isInvalid={this.checkValidField('value')}
									disabled={isReadOnly}
								/>
								<FormControl.Feedback type="invalid">
									{this.state.errors.value}
								</FormControl.Feedback>
							</FormGroup>
						</Col>
					</Row>
					{!isReadOnly &&
						<FormGroup controlId="observations">
							<FormLabel>Observaciones</FormLabel>
							<FormControl
								type="text"
								value={transaction.observations}
								onChange={this.handleChange}
								placeholder="Observaciones"
								isInvalid={this.checkValidField('observations')}
								disabled={isReadOnly}
								as="textarea"
							/>
							<FormControl.Feedback type="invalid">
								{this.state.errors.observations}
							</FormControl.Feedback>
						</FormGroup>
					}

				</div>

				<div className="modal-footer">
					<button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
					{!isReadOnly && (
						<SubmitButton
							type="button"
							isLoading={this.state.isLoading}
							text="Guardar"
							loadingText="Guardando..."
							onClick={this.handleSubmit}
						/>
					)}
				</div>
			</form>
		)
	}
}


const mapStateToProps = (reducers) => {
	return {
		...reducers.employeesReducer,
		...reducers.rulesReducer,
	}
};
export default connect(mapStateToProps, rulesActions)(TransactionEdit)