import React from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import moment from "moment";
import { errorFormating } from "utils/utils";
import "react-datepicker/dist/react-datepicker.css";
import EmployeeGroupForm from './EmployeeGroupForm';
import { employeesService } from "services";
import * as employeesActions from 'actions/employeesActions';

class EmployeeGroupEdit extends React.Component {
  constructor(props) {
    super(props);

    let employee_group = {
      group_id: 0,
      start_day: '',
      end_day: '',
    };

    let isEdit = false;

    if (props.employee_group) {
      employee_group = {
        ...props.employee_group,
        group_id: props.employee_group.group.id,
      };
      isEdit = true;
    }

    this.state = {
      isEdit,
      isLoading: false,
      submitted: false,
      errors: {},
      employee_group,
    };
  }

  checkValidField = (name) => {
    const { submitted, errors } = this.state;
    return submitted && errors[name] !== undefined && errors[name] !== '';
  };

  handleSubmit = async (employee_group) => {
    this.setState({ isLoading: true });

    const { isEdit } = this.state;
    const { employee } = this.props;
    let response;

    try {
      if (isEdit) {
        response = await employeesService.updateGroup(employee.id, employee_group);
      } else {
        response = await employeesService.addGroup(employee.id, employee_group);
      }

      if (response.ok) {
        this.setState({ isLoading: false, errors: {} }, () => {
          toastr.success('¡Bien!', 'Cambios guardados correctamente');
          this.props.getEmployeeGroups(employee.id);
          this.props.handleClose();
        });
      } else {
        throw response;
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        submitted: true,
        errors: errorFormating(error),
      });
    }
  };

  render() {
    const { groups } = this.props;
    const { employee_group, errors, isLoading } = this.state;

    return (
      <EmployeeGroupForm
        initialEmployeeGroup={employee_group}
        groups={groups}
        errors={errors}
        handleSubmit={this.handleSubmit}
        isLoading={isLoading}
        checkValidField={this.checkValidField}
        handleClose={this.props.handleClose}
      />
    );
  }
}

const mapStateToProps = (state, myProps) => ({
  ...state.employeesReducer,
  groups: state.groupsReducer.groups,
  employee: myProps.employee,
});

export default connect(mapStateToProps, employeesActions)(EmployeeGroupEdit);
