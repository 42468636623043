import { handleResponse } from "../utils/utils";
import { companyService, timeTypesService } from "services";

export const authService = {
	login,
	register,
	getToken,
	getCurrent,
	signup,
	logout,
	cleanStorage,
	setToken,
	setUser,
	checkCookieAndSetToken,
	initializeAuthService,
	getTimeRegistrationToken,
};

try {
	authService.currentUser = JSON.parse(localStorage.getItem("user"));
	authService.token = JSON.parse(localStorage.getItem("token"));
	authService.isFromHaddock = false;
	const domain = window.location.hostname;
	authService.isHaddockDomain = (domain === 'haddock.local' || domain === 'hr.haddock.app');

	// Verificar si el token viene de Haddock
	if (getCookie('__plain_session')) {
		authService.isFromHaddock = true;
		console.log("Hay cookie");
	}


} catch (e) { console.error("Error inicializando el authService", e); }

function initializeAuthService() {
	console.log("initializeAuthService");
	if (authService.isFromHaddock) {
		const cookieToken = getCookie('__plain_session');

		// Si la cookie no existe, limpiar el almacenamiento (hacer logout)
		if (!cookieToken) {
			// console.log("no hay cookie, clean storage");
			cleanStorage();
			return;
		} else {
			// Si la cookie existe pero ha cambiado, actualizar el token y recargar el usuario actual
			if (!authService.currentUser || !authService.token || cookieToken !== authService.token.access_token) {
				const user = checkCookieAndSetToken();
				return user;
			}
		}
	}
	return authService.currentUser || null;
}

// get token from user
async function login(username, password) {
	if (authService.isFromHaddock) {
		// si es haddock borra la cookie antes de hacer login
		deleteCookie("__plain_session");
	}

	const token = await getToken(username, password);

	if (token.ok) {
		// limpiar avatarsDB en indexedDB, que es el que almacena los avatares
		await clearIndexedDB();

		setToken(token);

		const user = await getCurrent();
		// console.log(user);
		if (user === undefined) {
			return {};
		} else if (user.ok) {
			await setUser(user);
			// await handleSkin();

			return {
				ok: true,
				user,
				token,
			};
		} else {
			return { errors: user.errors, ok: false };
		}
	} else {
		return { errors: token.errors, ok: false };
	}
}


// new user (and company) registration + login

async function register(user) {
	// Verifica si es un usuario de Haddock, en cuyo caso no aplica el registro
	if (authService.isFromHaddock) {
		return { ok: false, error_message: "El registro no aplica para usuarios de Haddock" };
	}

	const newUser = await signup(user);
	if (newUser.ok) {
		const token = await getToken(user.email, user.password);
		// console.log(token);

		if (token.ok) {
			setToken(token);
			await setUser(newUser);
			// await handleSkin();

			return {
				ok: true,
				user: newUser,
				token,
			};
		} else {
			return { errors: token.errors, ok: false };
		}
	} else {
		return { errors: newUser.errors, ok: false };
	}
}



// get token from user
function getToken(username, password) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${global.API_KEY}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			grant_type: "password",
			username,
			password,
		}),
	};


	return fetch(`${global.BASE_API}auth/token/`, requestOptions).then(
		handleResponse
	);
}

// gets current user
function getCurrent() {
	if (!authService.token) {
		return Promise.resolve({});
	}

	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
	};

	return fetch(`${global.BASE_API}employees/current/`, requestOptions).then(
		handleResponse
	);
}

// new employee signup
function signup(user) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${global.API_KEY}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(user),
	};

	return fetch(`${global.BASE_API}apps/signup/`, requestOptions).then(
		handleResponse
	);
}

// remove token & user from local storage to log user out
function logout(redirectUrl = "/login") {
	// Si el token viene de Haddock, verifica si la cookie ha cambiado
	if (authService.isFromHaddock || authService.isHaddockDomain) {
		console.log("borrando cookie");
		deleteCookie("__plain_session");
	}

	cleanStorage();

	// Redirige si se proporciona una URL; de lo contrario, usa la predeterminada
	if (redirectUrl) {
		window.location.href = redirectUrl;
	}
}


// Función para limpiar IndexedDB
function clearIndexedDB() {
	return new Promise((resolve, reject) => {
		const request = indexedDB.deleteDatabase('avatarsDB');

		request.onsuccess = () => {
			resolve();
		};

		request.onerror = (event) => {
			reject(event.target.errorCode);
		};
	});
}

async function cleanStorage() {
	// console.log("cleanStorage");
	localStorage.removeItem("token");
	localStorage.removeItem("user");
	authService.token = null;
	authService.currentUser = null;

	// limpiar avatarsDB en indexedDB, que es el que almacena los avatares
	await clearIndexedDB();
}

function setToken(token, isFromHaddock = false) {
	// console.log("setToken");
	if (token) {
		localStorage.setItem("token", JSON.stringify(token));
		authService.token = token;
		authService.isFromHaddock = isFromHaddock;
	} else {
		authService.token = null;
		authService.isFromHaddock = false;
	}
}

async function setUser(user) {
	// console.log("setUser");
	var subscription = await companyService.getSubscriptions(user.company.id);
	// console.log(subscription);
	if (subscription.ok) {
		user.subscription = subscription[0];
	}

	var employee_with_time_types = await timeTypesService.getContractTimeTypes({ employee_id: user.id });
	user.employee_with_time_types = employee_with_time_types.ok ? employee_with_time_types : [];


	localStorage.setItem("user", JSON.stringify(user));
	authService.currentUser = user;
}


function getCookie(name) {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
}

function deleteCookie(name) {
	document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

async function checkCookieAndSetToken() {
	const cookieToken = getCookie('__plain_session');
	if (!cookieToken) {
		console.warn("No hay cookie presente. No se intenta autenticar.");
		return null; // Detén el flujo si no hay cookie
	}

	try {
		// Si existe la cookie, guarda el token en localStorage y authService
		setToken({ access_token: cookieToken }, true);

		const user = await getCurrent();
		if (user && user.ok) {
			await setUser(user);
			return user;
		} else {
			console.warn("El token es inválido o el usuario no es válido. Cerrando sesión.");
			authService.logout();
			return null;
		}
	} catch (error) {
		console.error("Error al obtener el usuario actual:", error);
		authService.logout();
		return null;
	}
}

// async function handleSkin() {
// 	const domain = window.location.hostname;
// 	let skinFolder = '';

// 	// Determina el nombre de la carpeta según el dominio
// 	if (domain === 'haddock.local' || domain === 'hr.haddock.app') {
// 		skinFolder = 'haddock';
// 		// } else if (domain === 'otro.local') {
// 		//     skinFolder = 'otro';
// 	} else {
// 		skinFolder = 'default'; // Dominio por defecto
// 	}

// 	// Almacena el nombre de la carpeta en localStorage
// 	localStorage.setItem('skin', skinFolder);
// }


function getTimeRegistrationToken() {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
	};

	return fetch(`${global.BASE_API}auth/time-registration-token/`, requestOptions).then(
		handleResponse
	);
}