import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiPlus, FiSearch } from "react-icons/fi";
import { FormGroup, FormControl } from "react-bootstrap";
import Loading from 'components/Loading';
import Layout from 'components/Layout';
import ShiftList from 'components/Shifts';
import ShiftEditModal from 'components/Shifts/ShiftEditModal';
import { showModal, hideModal } from "actions/modalActions";
import * as shiftsActions from 'actions/shiftsActions';
import * as calendarsActions from 'actions/calendarsActions';
import * as timesActions from 'actions/timesActions';
import * as groupsActions from 'actions/groupsActions';
import Modal from 'components/Modal';

class Shifts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchShift: '',
      filterTypeId: ''
    }

    // console.log(props);
    this.closeModal = this.closeModal.bind(this);
    this.openNewShiftModal = this.openNewShiftModal.bind(this);
    this.getShifts = this.getShifts.bind(this);
  }

  componentDidMount() {
    this.getShifts();
    this.props.getShiftTypes();
    this.props.getSelectableTimeTypes();
    this.props.getDefaultTimeType();
    this.props.getSelectableDayTypes();
    this.props.getAllGroups();
  }

  handleChangeSearch = event => {
    this.setState({
      [event.target.id]: event.target.value
    }, () => {
      this.getShifts()
    });
  }

  async getShifts() {
    await this.props.getAllShifts({ q: this.state.searchShift, shift_type: this.state.filterTypeId });
  }

  closeModal() {
    this.props.hideModal()
  }

  openNewShiftModal() {
    this.props.showModal({
      open: true,
      title: 'Nuevo turno',
      style: { width: '550px' },
      content: <ShiftEditModal getShifts={this.getShifts} handleClose={this.closeModal} />,
      closeModal: this.closeModal
    }, 'edit')
  }

  getContent = () => {
    if (this.props.loading) {
      return <Loading />;
    }

    if (!this.props.shifts) {
      return "Error cargando los turnos";
    }

    return (<ShiftList shifts={this.props.shifts} getShifts={this.getShifts} />);
  }

  renderFilters = () => {
    const { shift_types } = this.props;
    const optionList = shift_types.length > 0 && shift_types.map((item, i) => {
      return (
        <option key={i} value={item.name}>{item.name}</option>
      )
    }, this);

    return (
      <div className="heading-filters">
        <FormGroup className="form-search" controlId="searchShift">
          <FormControl
            type="text"
            placeholder="Buscar..."
            value={this.state.searchShift}
            onChange={this.handleChangeSearch}
          />
          <FiSearch />
        </FormGroup>
        <FormGroup className="form-search" controlId="filterTypeId">
          <FormControl
            type="text"
            placeholder="Buscar..."
            value={this.state.filterTypeId}
            onChange={this.handleChangeSearch}
            as="select">
            <option value="">Filtrar por tipo</option>
            {optionList}
          </FormControl>
        </FormGroup>
      </div>
    );
  }

  render() {
    return (
      <>
        <Layout className="page-shifts" context="shifts">

          <div className="heading">

            <h1 className="title">Turnos</h1>

            {this.renderFilters()}

            <div className="heading-actions">
              <button type="button" title="Crear turno" onClick={this.openNewShiftModal} className="btn btn-new"><FiPlus /></button>
            </div>

          </div>

          {this.getContent()}

        </Layout>

        <Modal hideModal={this.props.hideModal} />
      </>
    )
  }
}



const mapStateToProps = (reducers) => {
  return {
    ...reducers.calendarsReducer,
    ...reducers.timesReducer,
    ...reducers.shiftsReducer,
    ...reducers.groupsReducer,
    loading: reducers.shiftsReducer.loading
  }
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }))
  },
  getAllShifts: (options) => dispatch(shiftsActions.getAllShifts(options)),
  getShiftTypes: () => dispatch(shiftsActions.getShiftTypes()),
  getAllGroups: () => dispatch(groupsActions.getAllGroups()),
  getSelectableTimeTypes: () => dispatch(timesActions.getSelectableTimeTypes()),
  getDefaultTimeType: () => dispatch(timesActions.getDefaultTimeType()),
  getSelectableDayTypes: () => dispatch(calendarsActions.getSelectableDayTypes()),
})


export default connect(mapStateToProps, mapDispatchToProps)(Shifts)