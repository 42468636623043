import React, { useState, useEffect } from 'react';
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { FiCalendar } from "react-icons/fi";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import SubmitButton from "components/SubmitButton";
registerLocale("es", es);

const EmployeeGroupForm = ({
  initialEmployeeGroup,
  groups,
  errors,
  handleSubmit,
  isLoading,
  checkValidField,
  handleClose,
  showCancelButton = true,
  cancelButtonText = "Cancelar",
}) => {
  const [employee_group, setEmployeeGroup] = useState(initialEmployeeGroup);

  useEffect(() => {
    setEmployeeGroup(initialEmployeeGroup);
  }, [initialEmployeeGroup]);

  const handleChange = (event) => {
    const { id, value } = event.target;
    setEmployeeGroup((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleDateChange = (id, date) => {
    setEmployeeGroup((prev) => ({
      ...prev,
      [id]: date ? moment(date).format("YYYY-MM-DD") : "",
    }));
  };

  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit(employee_group);
  };

  const optionList = groups.map((item, i) => (
    <option key={i} value={item.id}>{item.name}</option>
  ));

  return (
    <form onSubmit={onSubmit}>
      <div className="modal-body">
        <FormGroup controlId="group_id">
          <FormLabel>Grupo</FormLabel>
          <FormControl
            as="select"
            id="group_id"
            value={employee_group.group_id}
            onChange={handleChange}
            isInvalid={checkValidField("group_id")}
          >
            <option value="-1">Seleccionar grupo</option>
            {optionList}
          </FormControl>
          <FormControl.Feedback type="invalid">
            {errors.group_id}
          </FormControl.Feedback>
        </FormGroup>

        <FormGroup controlId="start_day" className={`form-date ${(employee_group.start_day === '' || !employee_group.start_day) && 'empty'}   ${checkValidField('start_day') && "is-invalid"}`}>
          <FormLabel>Fecha inicio</FormLabel>
          <DatePicker
            selected={employee_group.start_day ? moment(employee_group.start_day).toDate() : null}
            onChange={(date) => handleDateChange("start_day", date)}
            className="form-control"
            dateFormat="dd/MM/yyyy"
            placeholderText="dd/mm/aaaa"
            locale="es"
            isInvalid={checkValidField("start_day")}
          />
          <FiCalendar />
          <FormControl.Feedback type="invalid">
            {errors.start_day}
          </FormControl.Feedback>
        </FormGroup>

        <FormGroup controlId="end_day" className={`form-date ${checkValidField('end_day') && "is-invalid"}`}>
          <FormLabel>Fecha fin (opcional)</FormLabel>
          <DatePicker
            selected={employee_group.end_day ? moment(employee_group.end_day).toDate() : null}
            onChange={(date) => handleDateChange("end_day", date)}
            className="form-control"
            dateFormat="dd/MM/yyyy"
            placeholderText="dd/mm/aaaa"
            locale="es"
            isInvalid={checkValidField("end_day")}
          />
          <FiCalendar />
          <FormControl.Feedback type="invalid">
            {errors.end_day}
          </FormControl.Feedback>
        </FormGroup>
      </div>

      <div className="modal-footer">
        {showCancelButton && (
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={handleClose}
          >
            {cancelButtonText}
          </button>
        )}
        <SubmitButton
          type="submit"
          isLoading={isLoading}
          text="Guardar"
          loadingText="Guardando..."
        />
      </div>
    </form>
  );
};

export default EmployeeGroupForm;
