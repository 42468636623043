import React from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { FormGroup, FormControl, FormLabel, Col, Row } from "react-bootstrap";
import { FiCalendar } from "react-icons/fi";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import { errorFormating } from "utils/utils";
import Switch from "components/Switch";
import SubmitButton from "components/SubmitButton";
import { authService, employeesService } from "services";
import * as employeesActions from 'actions/employeesActions';
import * as groupsActions from 'actions/groupsActions';
import * as functionsActions from 'actions/functionsActions';
import * as ubicationsActions from 'actions/ubicationsActions';
import * as rulesActions from 'actions/rulesActions';

import EmployeeContractForm from './EmployeeContractForm';
import EmployeeGroupForm from './EmployeeGroupForm';

import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { id } from 'date-fns/locale';
registerLocale("es", es);

class EmployeeEdit extends React.Component {
  constructor(props) {
    super(props);

    const today = moment().format("YYYY-MM-DD");

    let employee_contract = {
      contract_id: 0,
      start_day: '',
      end_day: '',
    }

    let employee_group = {
      group_id: props.group_id ? props.group_id : -1,
      start_day: '',
      end_day: '',
    };

    this.state = {
      step: 1,
      employee: {
        name: "",
        surname: "",
        email: "",
        start_day: today,
        role: "EMPLOYEE",
        enabled: false,
        with_access: false,
      },
      isLoading: false,
      errors: {},
      employee_contract,
      employee_group,
    };
  }

  componentDidMount() {
    // carga las listas solo si vienen vacías (cuando se viene desde Plan)
    const {
      contracts,
      getContracts,
      groups,
      getAllGroups,
      select_functions,
      getSelectableFunctions,
      select_ubications,
      getSelectableUbications,
    } = this.props;

    // console.log(select_functions);
    // console.log(select_ubications);

    if (!contracts || contracts.length === 0) {
      getContracts();
    }

    if (!groups || groups.length === 0) {
      getAllGroups();
    }

    if (!select_functions || select_functions.length === 0) {
      getSelectableFunctions();
    }

    if (!select_ubications || select_ubications.length === 0) {
      getSelectableUbications();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.step !== this.state.step) {
      this.updateModalTitle();
    }
  }

  updateModalTitle = () => {
    const { step } = this.state;
    const { setModalTitle } = this.props;
    let title = "";

    switch (step) {
      case 1:
        title = "Editar empleado"; // solo se muestra cuando se vuelve atrás
        break;
      case 2:
        title = "Configuración de empleado";
        break;
      case 3:
        title = "Asignación de contrato";
        break;
      case 4:
        title = "Asignación de grupo";
        break;
      default:
        title = "Completado";
    }

    if (setModalTitle) {
      setModalTitle(title); // Llama a la función para actualizar el título
    }
  };

  handleChange = (event) => {
    const { employee } = this.state;
    this.setState({
      employee: {
        ...employee,
        [event.target.id]: event.target.value,
      },
    });
  };

  handleDateChange = (id, date) => {
    const { employee } = this.state;
    this.setState({
      employee: {
        ...employee,
        [id]: date ? moment(date).format("YYYY-MM-DD") : "",
      },
    });
  };

  handleSwitch = (event) => {
    const { employee } = this.state;
    const { id, checked } = event.target;

    this.setState({
      employee: {
        ...employee,
        [id]: checked,
        ...(id === "enabled" && !checked ? { with_access: false } : {}),
      },
    });
  };

  checkValidField = (name) => {
    const { errors } = this.state;
    return !!errors[name];
  };

  handleNext = async () => {
    const { step, employee } = this.state;

    this.setState({ isLoading: true });
    let response;

    try {
      if (step === 1) {
        // GUARDAR EMPLEADO
        const roles = [];
        roles.push(employee.role);

        const temp_employee = {
          ...employee,
          roles,
        }

        // console.log(temp_employee);

        if (employee.id) {
          // si está editando (volver atrás), update
          response = await employeesService.update(temp_employee);
        }
        else {
          response = await employeesService.add(temp_employee);

        }
        // response = { ok: true, id: 8024 };  // TEST RAPIDITO
        // console.log(response);

        if (response.ok) {
          toastr.success("¡Bien!", "Datos guardados correctamente");
          this.setState({
            step: step + 1,
            isLoading: false,
            employee: { ...employee, id: response.id }
          });
        } else {
          this.setState({
            isLoading: false,
            submitted: true,
            errors: errorFormating(response)
          });
        }

      } else if (step === 2) {
        // pantalla de confirmación

        this.setState({ step: step + 1, isLoading: false, });

      } else if (step === 3) {
        // asignar contrato
        // this.setState({ step: step + 1, isLoading: false, });
      } else if (step === 4) {
        // asignar grupo
        // this.setState({ step: step + 1, isLoading: false, });
      }
    } catch (error) {
      console.log(error);
      toastr.error("Error", "No se pudo guardar la información");
      this.setState({ errors: error.errors || {}, isLoading: false });
    }
  };

  handleBack = () => {
    this.setState((prevState) => ({
      step: prevState.step - 1,
    }));
  };

  handleJump = () => {
    this.setState((prevState) => ({
      step: prevState.step + 1,
    }));
  };

  submitEmployeeContract = async (employee_contract) => {
    const { step, employee } = this.state;
    const response = await employeesService.addContract(employee.id, employee_contract);

    if (response.ok) {
      toastr.success("¡Bien!", "Contrato asignado correctamente");
      this.setState({
        step: step + 1,
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
        submitted: true,
        errors: errorFormating(response)
      });
    }
  }

  submitEmployeeGroup = async (employee_group) => {
    const { step, employee } = this.state;
    const response = await employeesService.addGroup(employee.id, employee_group);
    const props = this.props;

    if (response.ok) {
      toastr.success("¡Bien!", "Grupo asignado correctamente");
      this.setState({
        step: step + 1,
        isLoading: false,
      }, () => {
        // PLAN => solo si el grupo guardado es el que venía por props, actualizar lista
        // TODO: si se va a usar en más sitios, refinar
        if (employee_group.group_id === props.group_id) {
          props.reloadData();
        }
      });
    } else {
      this.setState({
        isLoading: false,
        submitted: true,
        errors: errorFormating(response)
      });
    }
  }

  renderStep = () => {
    const { step, employee, errors, employee_contract, employee_group, isLoading } = this.state;
    const { contracts, groups } = this.props;
    const isActive = employee.enabled;
    const hasAccess = employee.with_access;

    switch (step) {
      case 1:
        return (
          <form>
            <FormGroup controlId="name">
              <FormLabel>Nombre <span className="label-required">*</span></FormLabel>
              <FormControl
                type="text"
                value={employee.name}
                onChange={this.handleChange}
                placeholder="Nombre empleado"
                isInvalid={this.checkValidField("name")}
              />
              <FormControl.Feedback type="invalid">
                {errors.name}
              </FormControl.Feedback>
            </FormGroup>

            <FormGroup controlId="surname">
              <FormLabel>Apellidos <span className="label-required">*</span></FormLabel>
              <FormControl
                type="text"
                value={employee.surname}
                onChange={this.handleChange}
                placeholder="Apellidos empleado"
                isInvalid={this.checkValidField("surname")}
              />
              <FormControl.Feedback type="invalid">
                {errors.surname}
              </FormControl.Feedback>
            </FormGroup>

            <FormGroup controlId="email">
              <FormLabel>Email <span className="label-required">*</span></FormLabel>
              <FormControl
                type="email"
                value={employee.email}
                onChange={this.handleChange}
                placeholder="nombre@miempresa.com"
                isInvalid={this.checkValidField("email")}
              />
              <FormControl.Feedback type="invalid">
                {errors.email}
              </FormControl.Feedback>
            </FormGroup>

            <FormGroup controlId="role">
              <FormLabel>Rol</FormLabel>
              <FormControl
                as="select"
                value={employee.role}
                onChange={this.handleChange}
                isInvalid={this.checkValidField("role")}
              >
                <option value="EMPLOYEE">Empleado</option>
                <option value="ADMIN">Administrador</option>
              </FormControl>
              <FormControl.Feedback type="invalid">
                {errors.role}
              </FormControl.Feedback>
            </FormGroup>

            <FormGroup controlId="start_day" className={`form-date ${employee.start_day === "" && "empty"}`}>
              <FormLabel>Fecha contratación <span className="label-required">*</span></FormLabel>
              <DatePicker
                selected={employee.start_day ? moment(employee.start_day).toDate() : null}
                onChange={(date) => this.handleDateChange("start_day", date)}
                className="form-control"
                dateFormat="dd/MM/yyyy"
                locale="es"
                placeholderText="dd/mm/aaaa"
              />
              <FiCalendar />
              <FormControl.Feedback type="invalid">
                {errors.start_day}
              </FormControl.Feedback>
            </FormGroup>

            <Row>
              <Col>
                <FormGroup controlId="enabled">
                  <FormLabel className="inline-block">Activo</FormLabel>
                  <Switch
                    controlId="enabled"
                    isOn={isActive}
                    handleToggle={this.handleSwitch}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup controlId="with_access">
                  <FormLabel className="inline-block">Acceso</FormLabel>
                  <Switch
                    controlId="with_access"
                    isOn={hasAccess}
                    handleToggle={this.handleSwitch}
                  />
                </FormGroup>
              </Col>
            </Row>
          </form>
        );
      case 2:
        return (
          <>
            <p><strong>El empleado se ha guardado correctamente.</strong></p>
            <p>A continuación puedes configurarlo paso a paso, haciendo click en el botón "Siguiente".</p>
          </>
        );
      case 3:
        return (
          <EmployeeContractForm
            initialEmployeeContract={employee_contract}
            contracts={contracts}
            errors={errors}
            handleSubmit={this.submitEmployeeContract}
            isLoading={isLoading}
            checkValidField={this.checkValidField}
            handleClose={this.handleBack}
            cancelButtonText="Atrás"
          />
        );
      case 4:
        return (
          <EmployeeGroupForm
            initialEmployeeGroup={employee_group}
            groups={groups}
            errors={errors}
            handleSubmit={this.submitEmployeeGroup}
            isLoading={isLoading}
            checkValidField={this.checkValidField}
            handleClose={this.handleJump}
            cancelButtonText="Saltar"
          />
        );
      default:
        return <div>El empleado ya está listo</div>;
    }
  };

  // REGLAS
  // Si no guarda contrato, no ofrecer posibilidad de grupo, funciones etc
  // Se puede saltar asignación, pero después de haber asignadi contrato
  // en un futuro actualizar lista de todo lo asignado

  render() {
    const { step, isLoading, employee } = this.state;
    const textButton = step === 1
      ? (employee?.id ? "Actualizar empleado" : "Crear empleado")
      : "Siguiente";
    return (
      <>
        <div className="modal-body">
          {this.renderStep()}
        </div>

        <div className="modal-footer">
          {step == 2 && (
            <button type="button" onClick={this.handleBack} className="btn btn-outline-primary">
              Atrás
            </button>
          )}
          {step < 3 && (
            <>
              {/* <button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button> */}
              <SubmitButton
                type="button"
                isLoading={isLoading}
                onClick={this.handleNext}
                text={textButton}
                loadingText="Guardando..."
              />
            </>
          )}

          {step === 5 && (
            <button type="button" onClick={this.props.handleClose} className="btn btn-primary">
              Terminar
            </button>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (reducers, myProps) => ({
  ...reducers.employeesReducer,
  ...reducers.groupsReducer,
  ...reducers.functionsReducer,
  ...reducers.ubicationsReducer,
  ...reducers.rulesReducer,
  employee: myProps.employee,
});

const mapDispatchToProps = (dispatch) => ({
  getContracts: () => dispatch(rulesActions.getContracts()),
  getAllGroups: () => dispatch(groupsActions.getAllGroups()),
  getSelectableFunctions: () => dispatch(functionsActions.getSelectableFunctions()),
  getSelectableUbications: () => dispatch(ubicationsActions.getSelectableUbications()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeEdit);
