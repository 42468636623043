import React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import { FormGroup, FormControl, FormLabel, } from "react-bootstrap";
import { FiCalendar } from "react-icons/fi";
import SubmitButton from "components/SubmitButton";
// import * as groupsActions from "actions/groupsActions";
import { plansService } from "services";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es"; // datepicker locale
registerLocale("es", es);

class PlanCopy extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);

		const start = this.props.start;
		const end = this.props.end;
		const startDate = moment(start);
		const endDate = moment(end);
		const dayOfWeekNumeric = startDate.day(); // Obtener el día de la semana (0 = Domingo, 6 = Sábado)
		const minDate = endDate.add(1, 'days').toDate(); // Sumar un día
		const totalDays = endDate.diff(startDate, "days");


		this.state = {
			start,
			end,
			group: this.props.group,
			target_start: null,
			isLoading: false,
			submitted: false,
			error_footer: "",
			errors: {},
			dayOfWeekNumeric,
			minDate,
			totalDays,
		};
	}

	handleDateChange = (id, date) => {
		let key = id;
		let value = date ? moment(date).format("YYYY-MM-DD") : null;

		this.setState({
			[key]: value,
		});
	};


	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
		});
	};

	checkValidField = (name) => {
		return (
			this.state.submitted &&
			this.state.errors[name] !== undefined &&
			this.state.errors[name] !== ""
		);
	};

	// copy plan
	handleSubmit = async (event) => {
		event.preventDefault();
		const { group, start, end, target_start, totalDays } = this.state;
		const props = this.props;
		const group_id = group.id;
		this.setState({ isLoading: true });

		// ENVIAR:
		const obj = {
			search_request: {
				page_size: 1000,
				start,
				end,
				group_id
			},
			target_start
		};
		// console.log(obj);
		const response = await plansService.copy(obj);

		if (response.ok) {
			toastr.success("¡Bien!", "Plan copiado correctamente");

			// cargar nuevo plan
			const targetStartDate = moment(target_start);
			const target_end = targetStartDate.add(totalDays - 1, "days").format("YYYY-MM-DD");

			const newURL = `/plans/${group_id}/${target_start}@${target_end}`;
			window.history.pushState(null, "", newURL);
			props.updateStateAndReload({ group_id, start: target_start, end: target_end });
			props.handleClose();
			this.setState({ isLoading: false });
		}
		else {
			// show error
			let error_footer = response.errors[0].description;
			// console.log(response.errors);

			this.setState({
				submitted: true,
				error_footer,
				isLoading: false
			});
		}

		// {
		// 	"status": 400,
		// 	"errors": [
		// 		{
		// 			"code": "not_valid",
		// 			"key": "planAssignmentFullCopy.holiday_or_absence_plans_not_allowed_for_copy",
		// 			"fields": [
		// 				"target_start"
		// 			],
		// 			"description": "El plan a copiar no debe tener ningún turno de ausencia o vacaciones planificado"
		// 		}
		// 	]
		// }
	};

	isWeekDayOK = (date) => {
		return date.getDay() === this.state.dayOfWeekNumeric; // Lunes (0 es domingo, 1 es lunes, etc.)
	};

	render() {
		const { target_start, minDate, totalDays, errors, error_footer, group } = this.state;

		return (
			<form onSubmit={this.handleSubmit}>
				<div className="modal-body plan-opener-modal">

					<FormGroup>
						<FormLabel>Grupo</FormLabel> {group.name}
						<p><FormLabel>Periodo seleccionado</FormLabel> {totalDays} días</p>
					</FormGroup>

					<p>Selecciona la fecha en la que empezará la planificación copiada</p>
					<FormGroup
						controlId="target_start"
						className={`form-date ${target_start === "" && "empty"}  ${this.checkValidField('target_start') && "is-invalid"}`}
					>
						<FormLabel>Nuevo inicio <span className="label-required">*</span></FormLabel>

						<DatePicker
							selected={(target_start === "" || !target_start) ? null : moment(target_start).toDate()}
							onChange={(date) => this.handleDateChange("target_start", date)}
							isInvalid={this.checkValidField("target_start")}
							className="form-control"
							dateFormat="dd/MM/yyyy"
							locale='es'
							placeholderText="dd/mm/aaaa"
							minDate={minDate} // Solo fechas desde la fecha mínima
							filterDate={this.isWeekDayOK} // Solo permitir el dia de la semana que corresponda
						/>
						<FiCalendar />
						<FormControl.Feedback type="invalid">
							{errors.target_start}
						</FormControl.Feedback>
					</FormGroup>


					{this.state.error_footer && (
						<div
							className="alert alert-danger alert-dismissible fade show"
							role="alert"
						>
							{error_footer}
						</div>
					)}
				</div>

				<div className="modal-footer">
					<button
						type="button"
						onClick={this.props.handleClose}
						className="btn btn-outline-primary"
					>
						Cancelar
					</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Pegar planificación"
						loadingText="Pegando..."
					/>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.groupsReducer,
		loading: reducers.groupsReducer.loading,
		user: reducers.authReducer.user,
	};
};


// const mapDispatchToProps = (dispatch) => ({
// 	getAllGroups: () =>
// 		dispatch(
// 			groupsActions.getAllGroups({ sort: "+name", variant: "detailed" })
// 		),
// });

export default connect(mapStateToProps, null)(PlanCopy);

