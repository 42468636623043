import React from "react";
import { FormControl, FormGroup, FormLabel } from "react-bootstrap";
import Layout from "components/Layout";
import SubmitButton from "components/SubmitButton";
import { workflowsService } from "services";
import { errorFormating } from "utils/utils";
import Switch from "components/Switch";

class AbsenceImports extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			submitted: false,
			errors: {},
			importErrors: [],
			importMessages: [],
			selectedFile: null,
			ignore_errors: false,
		};
	}

	handleSwitch = (event) => {
		this.setState({
			[event.target.id]: event.target.checked,
		});
	};

	async handleDownloadTemplate() {
		const response = await workflowsService.downloadImportTemplate();

		const url = window.URL.createObjectURL(response);
		const a = document.createElement("a");
		document.body.appendChild(a);
		a.href = url;
		a.download = "import_template.xlsx";
		a.click();
	}

	handleImport = async (event) => {
		event.preventDefault();
		this.setState({ isLoading: true });

		const { ignore_errors, selectedFile } = this.state;

		if (selectedFile) {
			const formData = new FormData();
			formData.append("filename", selectedFile);
			const response = await workflowsService.prepareImport(formData);

			if (ignore_errors) {
				// Importar aunque haya errores
				this.runImport(response.token);
			} else {
				// Procesar errores/mensajes
				this.handleResponse(response);
			}
		} else {
			this.setState({
				isLoading: false,
				submitted: true,
				importMessages: [],
				importErrors: [],
				errors: {
					message:
						"Debes seleccionar el archivo en formato excel con los empleados a importar",
				},
			});
		}
	};

	handleResponse = (response) => {
		const importErrors = [];

		// Procesar errores de filas
		if (Array.isArray(response.rows) && response.rows.length > 0) {
			importErrors.push(...response.rows);
		}

		// Procesar errores de cabeceras
		if (response.header && Array.isArray(response.header.errors)) {
			importErrors.push(...response.header.errors);
		}

		this.setState({
			isLoading: false,
			submitted: true,
			errors: response.ok ? {} : { message: "Se ha producido un error" },
			importMessages: [],
			importErrors,
		});
	};

	runImport = async (token) => {
		this.setState({ isLoading: true });
		const response = await workflowsService.runImport(token);

		if (response.ok) {
			this.setState({
				isLoading: false,
				errors: {},
				importErrors: response.has_errors && response.rows.length > 0 ? response.rows : [],
				importMessages: response.rows.length > 0 ? response.rows : [],
			});
		} else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response),
			});
		}
	};

	getImportErrors = (rows = [], headerErrors = []) => {
		// Procesar errores de filas
		const rowErrors = rows.map((row, i) => {
			const hasErrors = row.errors && row.errors.length > 0;
			if (!hasErrors) {
				return row.message ? (
					<li key={`row-${i}`}>
						<span className="import-count">{row.message}</span>
					</li>
				) : null;
			}
			return (
				<li key={`row-${i}`}>
					<span className="import-count">Fila {row.row}</span>
					<ul className="import-details">
						{this.getImportErrorsDetails(row.errors)}
					</ul>
				</li>
			);
		});

		// Procesar errores de cabeceras
		const headerErrorMessages = Array.isArray(headerErrors)
			? headerErrors.map((error, i) => (
				<li key={`header-${i}`}>
					<span className="import-header-error">{error.message}</span>
				</li>
			))
			: [];

		return [...headerErrorMessages, ...rowErrors];
	};

	getImportErrorsDetails = (errors) =>
		(errors || []).map((error, i) => (
			<li key={i}>{error.message}</li>
		));

	getImportMessages = (rows = []) =>
		rows.map((row, i) => {
			const hasErrors = row.errors && row.errors.length > 0;
			if (!hasErrors && row.message) {
				return (
					<li key={i}>
						<span className="import-count">{row.message}</span>
					</li>
				);
			}
			return hasErrors ? null : null;
		});

	handleChange = (event) => {
		this.setState({
			selectedFile: event.target.files[0],
		});
	};

	getContent = () => (
		<form onSubmit={this.handleImport}>
			<ol className="steps">
				<li className="step-download">
					Descarga{" "}
					<span
						className="link"
						onClick={() => this.handleDownloadTemplate()}
					>
						la plantilla de ejemplo
					</span>{" "}
					en formato xls
				</li>
				<li className="step-upload">
					<div className="step-upload-content">
						Sube la plantilla con los datos de ausencias que vas a importar
						<FormControl
							type="file"
							onChange={this.handleChange}
							className="form-control"
						/>
					</div>
				</li>
				<li className="step-config">
					<div className="step-config-content">
						En caso de error, indicar si importar de todos modos o cancelar la importación
						<FormGroup
							className="form-group-flex form-row-switch form-group-main"
							controlId="ignore_errors"
						>
							<FormLabel>Importar aunque haya errores</FormLabel>
							<Switch
								controlId="ignore_errors"
								isOn={this.state.ignore_errors}
								handleToggle={this.handleSwitch}
							/>
						</FormGroup>
					</div>
				</li>
			</ol>
			<SubmitButton
				type="submit"
				isLoading={this.state.isLoading}
				text="Importar Ausencias"
				loadingText="Importando..."
			/>
		</form>
	);

	render() {
		const { importErrors, importMessages, errors, ignore_errors } = this.state;

		return (
			<Layout className="page-imports">
				<div className="heading">
					<div className="heading-left">
						<h1 className="title">Importar ausencias</h1>
					</div>
				</div>

				{this.getContent()}

				{errors.message && <p className="error-message">{errors.message}</p>}

				{!ignore_errors && importErrors.length > 0 && (
					<div className="import-list import-errors">
						<p className="import-list-text">
							Se han encontrado los siguientes errores en la importación:
						</p>
						<ul className="import-rows">
							{this.getImportErrors(
								importErrors.filter((error) => error.row), // Filtrar errores de filas
								importErrors.filter((error) => !error.row) // Filtrar errores de cabeceras
							)}
						</ul>
					</div>
				)}

				{importMessages.length > 0 && (
					<div className="import-list import-messages">
						<p className="import-list-text">Resultado de la importación</p>
						<ul className="import-rows">
							{this.getImportMessages(importMessages)}
						</ul>
					</div>
				)}
			</Layout>
		);
	}
}

export default AbsenceImports;
