import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import moment from "moment";
import { errorFormating } from "utils/utils";
import "react-datepicker/dist/react-datepicker.css";
import EmployeeContractForm from './EmployeeContractForm';
import { employeesService } from "services";
import * as employeesActions from 'actions/employeesActions';

class EmployeeContractEdit extends React.Component {
  constructor(props) {
    super(props);

    let employee_contract = {
      contract_id: 0,
      start_day: '',
      end_day: '',
    }

    let isEdit = false;

    if (props.employee_contract) {
      employee_contract = {
        ...props.employee_contract,
        contract_id: props.employee_contract.contract.id
      };
      isEdit = true;
    }

    this.state = {
      isEdit,
      isLoading: false,
      submitted: false,
      errors: {},
      employee_contract
    };
  }



  checkValidField = (name) => {
    const { submitted, errors } = this.state;
    return submitted && errors[name] !== undefined && errors[name] !== "";
  };

  handleSubmit = async (employee_contract) => {
    //event.preventDefault();


    this.setState({ isLoading: true });
    let response = '';

    const props = this.props;
    // const { employee_contract } = this.state;

    // if edit => update; else => new    
    if (this.state.isEdit) {
      response = await employeesService.updateContract(props.employee.id, employee_contract);
    }
    else {
      response = await employeesService.addContract(props.employee.id, employee_contract);
    }

    if (response.ok) {
      this.setState({
        isLoading: false,
        errors: {},
      }, () => {

        toastr.success('¡Bien!', 'Cambios guardados correctamente');
        this.props.getEmployeeContracts(props.employee.id);
        props.handleClose();

      });
    }
    else {
      this.setState({
        isLoading: false,
        submitted: true,
        errors: errorFormating(response)
      });
    }
  };

  render() {
    const { contracts } = this.props;
    const { employee_contract, errors, isLoading } = this.state;

    return (
      <EmployeeContractForm
        initialEmployeeContract={employee_contract}
        contracts={contracts}
        errors={errors}
        handleSubmit={this.handleSubmit}
        isLoading={isLoading}
        checkValidField={this.checkValidField}
        handleClose={this.props.handleClose}
      />
    );
  }
}

const mapStateToProps = (state, myProps) => ({
  ...state.employeesReducer,
  contracts: state.rulesReducer.contracts,
  employee: myProps.employee,
});

export default connect(mapStateToProps, employeesActions)(EmployeeContractEdit);
