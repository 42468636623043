import React, { Component } from "react";
import { connect } from "react-redux";
import { FiPlus, FiSearch, FiRefreshCcw, FiDownload, FiMoreHorizontal } from "react-icons/fi";
import { FormGroup, FormControl, Dropdown, FormLabel } from "react-bootstrap";
import Loading from "components/Loading";
import Layout from "components/Layout";
import Switch from "components/Switch";
import ShiftPatternsList from "components/ShiftPatterns";
import ShiftPatternEditModal from "components/ShiftPatterns/ShiftPatternEditModal";
import { showModal, hideModal } from "actions/modalActions";
import * as shiftsActions from "actions/shiftsActions";
import * as ubicationsActions from "actions/ubicationsActions";
import Modal from "components/Modal";
import { helpUrls } from "utils/helpUrls";

class ShiftPatterns extends Component {
	constructor(props) {
		super(props);

		this.state = {
			active_only: true,
			search_shift_pattern: "",
		};

		// console.log(props);
		this.closeModal = this.closeModal.bind(this);
		this.openNewShiftPatternModal = this.openNewShiftPatternModal.bind(this);
		this.getShiftPatterns = this.getShiftPatterns.bind(this);
	}

	componentDidMount() {
		this.getShiftPatterns();
		this.props.getSelectableShifts();
		this.props.getSelectableUbications();
		this.props.getShiftTypes();
		this.props.getDefaultShift();
	}

	handleChangeSearch = event => {
		const targetId = event.target.id;

		this.setState({
			[targetId]: targetId === "active_only" ? event.target.checked : event.target.value
		}, () => {
			this.getShiftPatterns()
		});
	}

	async getShiftPatterns() {
		await this.props.getAllShiftPatterns({ q: this.state.search_shift_pattern, enabled: this.state.active_only });
	}

	closeModal() {
		this.props.hideModal();
	}

	openNewShiftPatternModal() {
		this.props.showModal(
			{
				open: true,
				title: "Nueva rueda de turnos",
				style: { width: "850px" },
				content: <ShiftPatternEditModal getShiftPatterns={this.getShiftPatterns} handleClose={this.closeModal} />,
				closeModal: this.closeModal,
			},
			"edit"
		);
	}

	getContent = () => {
		if (this.props.loading) {
			return <Loading />;
		}

		if (!this.props.shift_patterns) {
			return "Error cargando las ruedas de turnos";
		}

		if (this.props.shift_patterns.length == 0) {
			return (
				<div className="first-steps">
					<FiRefreshCcw className="icono-pasos" />
					<h3 className="title2">Primeros pasos</h3>

					<p>
						Las <strong>ruedas de turnos</strong> son los patrones semanales de{" "}
						<strong>turnos de trabajo</strong> (también llamados
						"horarios") de los <strong>empleados</strong>. Aquí te explicamos
						cómo funcionan:{" "}
						<a
							className="alink"
							target="_blank"
							href={helpUrls.shift_pattern}
						>
							{helpUrls.shift_pattern}
						</a>
					</p>
				</div>
			);
		}

		return <ShiftPatternsList getShiftPatterns={this.getShiftPatterns} shift_patterns={this.props.shift_patterns} />;
	};

	render() {
		const { active_only, search_shift_pattern } = this.state;

		return (
			<>
				<Layout className="page-shift-patterns" context="shifts">
					<div className="heading">
						<h1 className="title">Ruedas de turnos</h1>

						<div className="heading-filters">
							<FormGroup
								className="form-group-flex form-switch"
								controlId="active_only"
							>
								<Switch
									controlId="active_only"
									isOn={active_only}
									handleToggle={this.handleChangeSearch}
								/>
								<FormLabel>Mostrar activos</FormLabel>
							</FormGroup>

							<FormGroup className="form-search" controlId="search_shift_pattern">
								<FormControl
									type="text"
									placeholder="Buscar..."
									value={search_shift_pattern}
									onChange={this.handleChangeSearch}
								/>
								<FiSearch />
							</FormGroup>
						</div>

						<div className="heading-actions">
							<button
								type="button"
								title="Crear rueda de turno"
								onClick={this.openNewShiftPatternModal}
								className="btn btn-new"
							>
								<FiPlus />
							</button>

							<Dropdown>
								<Dropdown.Toggle variant="action">
									<FiMoreHorizontal />
								</Dropdown.Toggle>
								<Dropdown.Menu alignRight>
									<Dropdown.Item
										as="button"
										onClick={() =>
											this.props.history.push("/shift-patterns/import")
										}
									>
										<FiDownload /> Importar rueda de turnos
									</Dropdown.Item>
									<Dropdown.Item
										as="button"
										onClick={() =>
											this.props.history.push("/employees/shift-patterns/import")
										}
									>
										<FiDownload /> Importar asignación de rueda de turnos a empleados
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{this.getContent()}
				</Layout>

				<Modal hideModal={this.props.hideModal} />
			</>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.shiftsReducer,
		...reducers.ubicationsReducer,
		loading: reducers.shiftsReducer.loading,
	};
};

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }));
	},
	getAllShiftPatterns: (options) => dispatch(shiftsActions.getAllShiftPatterns(options)),
	getShiftTypes: () => dispatch(shiftsActions.getShiftTypes()),
	getSelectableShifts: () => dispatch(shiftsActions.getSelectableShifts()),
	getSelectableUbications: (options) => dispatch(ubicationsActions.getSelectableUbications(options)),
	getAllShifts: (options) => dispatch(shiftsActions.getAllShifts(options)),
	getDefaultShift: () => dispatch(shiftsActions.getDefaultShift()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShiftPatterns);
