import React from "react";
import { FormControl } from "react-bootstrap";
import Layout from "components/Layout";
import SubmitButton from "components/SubmitButton";
import { employeesService } from "services";
import { errorFormating } from "utils/utils";

class EmployeeImports extends React.Component {
	constructor(props) {
		super(props);
		//console.log(props);
		this.state = {
			isLoading: false,
			submitted: false,
			errors: {},
			importErrors: [],
			importMessages: [],
			selectedFile: null,
		};
	}

	async handleDownloadTemplate() {
		const response = await employeesService.downloadImportTemplate();

		var url = window.URL.createObjectURL(response);
		var a = document.createElement("a");
		document.body.appendChild(a);
		a.href = url;
		a.download = "import_template.xlsx";
		a.click();
	}

	handleImport = async (event) => {
		event.preventDefault();
		this.setState({ isLoading: true });

		if (this.state.selectedFile) {
			var formData = new FormData();
			formData.append("filename", this.state.selectedFile);
			const response = await employeesService.prepareImport(formData);

			// console.log(response);

			if (response.ok && !response.has_errors) {
				this.setState(
					{
						isLoading: false,
						errors: {},
						importMessages: [],
						importErrors: [],
					},
					() => {
						if (response.token) {
							this.runImport(response.token);
						}
					}
				);
			} else if (!response.ok) {
				this.setState({
					isLoading: false,
					submitted: true,
					importMessages: [],
					importErrors: [],
					errors: {
						message:
							"Se ha producido un error",
					},
				});
			}
			else {

				var importErrors = [];
				// Procesar errores de filas
				if (Array.isArray(response.rows) && response.rows.length > 0) {
					importErrors = importErrors.concat(response.rows);
				}
				// Procesar errores de cabeceras
				if (response.header && Array.isArray(response.header.errors) && response.header.errors.length > 0) {
					importErrors = importErrors.concat(response.header.errors);
				}

				this.setState({
					isLoading: false,
					submitted: true,
					errors: response.rows && response.rows.length > 0 ? {} : response.errors?.[0] || {},
					importMessages: [],
					importErrors,
				});
			}
		} else {
			this.setState({
				isLoading: false,
				submitted: true,
				importMessages: [],
				importErrors: [],
				errors: {
					message:
						"Debes seleccionar el archivo en formato excel con los empleados a importar",
				},
			});
		}
	};

	// send token and run import
	runImport = async (token) => {
		this.setState({ isLoading: true });

		const response = await employeesService.runImport(token);
		// console.log(response);

		if (response.ok) {
			this.setState({
				isLoading: false,
				errors: {},
				importErrors:
					response.has_errors && response.rows.length > 0 ? response.rows : [],
				importMessages: response.rows.length > 0 ? response.rows : [],
			});
		} else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response),
			});
		}
	};

	getImportErrors = (rows = [], headerErrors = []) => {
		// Procesar errores de filas
		const rowErrors = rows.map((row, i) => {
			const hasErrors = row.errors && row.errors.length > 0;
			if (!hasErrors) {
				return <li key={`row-${i}`} className="hidden"></li>;
			}
			return (
				<li key={`row-${i}`}>
					<span className="import-count">Fila {row.row}</span>
					<ul className="import-details">
						{this.getImportErrorsDetails(row.errors)}
					</ul>
				</li>
			);
		});

		// Procesar errores de cabeceras
		const headerErrorMessages = Array.isArray(headerErrors)
			? headerErrors.map((error, i) => (
				<li key={`header-${i}`}>
					<span className="import-header-error">{error.message}</span>
				</li>
			))
			: [];

		// Combinar y devolver errores
		return [...headerErrorMessages, ...rowErrors];
	};

	getImportErrorsDetails = (errors) =>
		(errors || []).map((error, i) => { // Asegurarse de que errors no sea undefined
			return <li key={i}>{error.message}</li>;
		});

	getImportMessages = (rows) =>
		rows.map((row, i) => {
			if (!row.errors || row.errors.length > 0) return false; // Verificar si row.errors existe y no tiene errores
			return (
				<li key={i}>
					<span className="import-count">Fila {row.row}</span>
					<span className="import-text">{row.message}</span>
				</li>
			);
		});

	handleChange = (event) => {
		this.setState({
			selectedFile: event.target.files[0],
		});
	};

	getContent = () => {
		return (
			<form onSubmit={this.handleImport}>
				<ol className="steps">
					<li className="step-download">
						Descarga{" "}
						<span
							className="link"
							onClick={() => this.handleDownloadTemplate()}
						>
							la plantilla de ejemplo
						</span>{" "}
						en formato xls
					</li>
					<li className="step-upload">
						<div className="step-upload-content">
							Sube la plantilla con los datos de los empleados que vas a
							importar
							<FormControl
								type="file"
								onChange={this.handleChange}
								className="form-control"
							/>
						</div>
					</li>
				</ol>
				<SubmitButton
					type="submit"
					isLoading={this.state.isLoading}
					text="Importar Empleados"
					loadingText="Importando..."
				/>
			</form>
		);
	};

	render() {
		const { importErrors, importMessages, errors } = this.state;

		return (
			<Layout className="page-imports">
				<div className="heading">
					<div className="heading-left">
						<h1 className="title">Importar empleados</h1>
					</div>
				</div>

				{this.getContent()}

				{errors.message && <p className="error-message">{errors.message}</p>}

				{importErrors.length > 0 && (
					<div className="import-list import-errors">
						<p className="import-list-text">
							Se han encontrado los siguientes errores en la importación:
						</p>
						<ul className="import-rows">
							{this.getImportErrors(
								importErrors.filter(row => row.row), // Filtrar errores de filas
								importErrors.filter(error => !error.row) // Filtrar errores de cabeceras
							)}
						</ul>
					</div>
				)}

				{importMessages.length > 0 && (
					<div className="import-list import-messages">
						<p className="import-list-text">Resultado de la importación</p>
						<ul className="import-rows">
							{this.getImportMessages(importMessages)}
						</ul>
					</div>
				)}
			</Layout>
		);
	}
}

export default EmployeeImports;
