import React, { Component } from "react";
import { FormGroup, FormControl } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import * as userActions from 'actions/userActions';
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import { timesService } from "services";
import { format } from "date-fns";
import Logo from "components/Logo";

class Clockin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      submitted: false,
      employee_code: "",
      time_response: null,
      user: {},
      errors: {},
      error_footer: ""
    };
  }

  enableSubmitButton() {
    return (this.state.employee_code.length > 0);
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  checkValidField = (name) => {
    return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });

    const { employee_code } = this.state;
    const token = localStorage.getItem("timeRegistrationToken");
    // console.log(token);

    if (!token) {
      toastr.error("Error de autenticación", "Token no encontrado. Por favor, vuelve a iniciar sesión.");
      this.props.history.push("/login");
      return;
    }

    const time = {
      employee_code: employee_code,
      time_registration_token: token
    }

    const time_response = await timesService.addPublic(time);
    // console.log(time_response);

    if (time_response.ok) {
      this.setState({
        time_response,
        submitted: true,
        isLoading: false,
        errors: {},
        error_footer: ""
      });


      // Volver a mostrar el formulario después de 5 segundos
      setTimeout(() => {
        this.setState({
          time_response: null,
          employee_code: "",
        });
      }, 5000);

    } else {
      this.setState({
        submitted: true,
        isLoading: false,
        errors: errorFormating(this.props),
        error_footer: time_response.errors?.[0]?.description || "Error desconocido"
      });
    }
  }

  TimeRegistrationMessage({ timeRegistration }) {
    if (!timeRegistration) {
      return null; // Si no hay datos, no mostramos nada
    }

    const { employee, start, end } = timeRegistration;

    // Formateamos la hora
    const formattedStartTime = format(new Date(start), "HH:mm");
    const formattedEndTime = end ? format(new Date(end), "HH:mm") : null;

    // Determinamos el tipo de mensaje
    return (
      <div className="box">
        <h3>{`Hola, ${employee.name}`}</h3>
        <p>
          {end ? (
            <>
              Has fichado salida a las <strong>{formattedEndTime}</strong>
            </>
          ) : (
            <>
              Has fichado entrada a las <strong>{formattedStartTime}</strong>
            </>
          )}
        </p>
      </div>
    );
  }

  render() {
    const { time_response } = this.state;

    return (
      <div className="Auth access">
        <Link to="/login">
          <Logo className="logo" type="logo" />
        </Link>

        <form onSubmit={this.handleSubmit}>
          {!time_response && <div className="box">
            <h2 className="title">Fichajes</h2>

            <p className="hello">Introduce tu código de empleado para fichar tu entrada o salida</p>
            <FormGroup controlId="employee_code">
              {/* <FormLabel>Código de empleado</FormLabel> */}
              <FormControl
                autoFocus
                type="text"
                value={this.state.code}
                onChange={this.handleChange}
                isInvalid={this.checkValidField('employee_code')}
                placeholder="Código de empleado"
              />
              <FormControl.Feedback type="invalid">
                {this.state.errors.employee_code}
              </FormControl.Feedback>
            </FormGroup>

            <SubmitButton
              block
              disabled={!this.enableSubmitButton()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Fichar"
              loadingText="Fichando..."
            />

            {this.state.error_footer && (
              <div
                className="alert alert-danger"
                role="alert"
              >
                {this.state.error_footer}
              </div>
            )}
          </div>}

          {time_response && this.TimeRegistrationMessage({ timeRegistration: time_response })}

          <p className="mt20">Volver a la pantalla de <Link to="/login">inicio de sesión</Link></p>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (reducers) => {
  return reducers.authReducer;
};

export default connect(mapStateToProps, userActions)(Clockin);