import React from "react";
import { Link } from "react-router-dom";
import { FormControl } from "react-bootstrap";
import Layout from "components/Layout";
import SubmitButton from "components/SubmitButton";
import { calendarsService } from "services";

class CalendarImports extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			submitted: false,
			errors: {},
			importErrors: [],
			importMessages: [],
			selectedFile: null,
		};
	}

	async handleDownloadTemplate() {
		const response = await calendarsService.downloadImportTemplate();

		const url = window.URL.createObjectURL(response);
		const a = document.createElement("a");
		document.body.appendChild(a);
		a.href = url;
		a.download = "import_template.xlsx";
		a.click();
	}

	handleImport = async (event) => {
		event.preventDefault();
		this.setState({ isLoading: true });

		if (this.state.selectedFile) {
			const formData = new FormData();
			formData.append("filename", this.state.selectedFile);

			const response = await calendarsService.prepareImport(formData);

			if (response.ok && !response.has_errors) {
				this.setState(
					{
						isLoading: false,
						errors: {},
						importMessages: [],
						importErrors: [],
					},
					() => {
						if (response.token) {
							this.runImport(response.token);
						}
					}
				);
			} else {
				const importErrors = [];

				// Agregar errores de filas
				if (Array.isArray(response.rows) && response.rows.length > 0) {
					importErrors.push(...response.rows);
				}

				// Agregar errores de cabeceras
				if (response.header && Array.isArray(response.header.errors)) {
					importErrors.push(...response.header.errors);
				}

				this.setState({
					isLoading: false,
					submitted: true,
					errors: response.ok ? {} : { message: "Se ha producido un error" },
					importMessages: [],
					importErrors,
				});
			}
		} else {
			this.setState({
				isLoading: false,
				submitted: true,
				importMessages: [],
				importErrors: [],
				errors: {
					message: "Debes seleccionar el archivo en formato excel con los días especiales a importar",
				},
			});
		}
	};

	runImport = async (token) => {
		this.setState({ isLoading: true });

		const response = await calendarsService.runImport(token);

		if (response.ok) {
			this.setState({
				isLoading: false,
				errors: {},
				importErrors: response.has_errors ? response.rows || [] : [],
				importMessages: response.rows || [],
			});
		} else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response),
			});
		}
	};

	getImportErrors = (rows = [], headerErrors = []) => {
		// Procesar errores de filas
		const rowErrors = rows.map((row, i) => {
			const hasErrors = row.errors && row.errors.length > 0;
			if (!hasErrors) {
				return row.message ? (
					<li key={`row-${i}`}>
						<span className="import-count">{row.message}</span>
					</li>
				) : null;
			}
			return (
				<li key={`row-${i}`}>
					<span className="import-count">Fila {row.row}</span>
					<ul className="import-details">
						{this.getImportErrorsDetails(row.errors)}
					</ul>
				</li>
			);
		});

		// Procesar errores de cabeceras
		const headerErrorMessages = headerErrors.map((error, i) => (
			<li key={`header-${i}`}>
				<span className="import-header-error">{error.message}</span>
			</li>
		));

		return [...headerErrorMessages, ...rowErrors];
	};

	getImportErrorsDetails = (errors = []) =>
		errors.map((error, i) => (
			<li key={i}>{error.message}</li>
		));

	getImportMessages = (rows = []) =>
		rows.map((row, i) => (
			<li key={i}>
				<span className="import-count">Fila {row.row}</span>
				<span className="import-text">{row.message}</span>
			</li>
		));

	handleChange = (event) => {
		this.setState({
			selectedFile: event.target.files[0],
		});
	};

	getContent = () => (
		<form onSubmit={this.handleImport}>
			<ol className="steps">
				<li className="step-download">
					Crea un <Link to="/calendars" className="alink">calendario</Link> y personaliza los días de la semana
				</li>
				<li className="step-download">
					Descarga{" "}
					<span
						className="link"
						onClick={() => this.handleDownloadTemplate()}
					>
						la plantilla de ejemplo
					</span>{" "}
					en formato xls
				</li>
				<li className="step-upload">
					<div className="step-upload-content">
						Sube la plantilla con los días especiales que vas a importar
						<FormControl
							type="file"
							onChange={this.handleChange}
							className="form-control"
						/>
					</div>
				</li>
			</ol>
			<SubmitButton
				type="submit"
				isLoading={this.state.isLoading}
				text="Importar días especiales"
				loadingText="Importando..."
			/>
		</form>
	);

	render() {
		const { importErrors, importMessages, errors } = this.state;

		return (
			<Layout className="page-imports">
				<div className="heading">
					<div className="heading-left">
						<h1 className="title">Importar calendario</h1>
					</div>
				</div>

				{this.getContent()}

				{errors.message && importErrors.length === 0 && (
					<p className="error-message">{errors.message}</p>
				)}

				{importErrors.length > 0 && (
					<div className="import-list import-errors">
						<p className="import-list-text">
							Se han encontrado los siguientes errores en la importación:
						</p>
						<ul className="import-rows">
							{this.getImportErrors(
								importErrors.filter((error) => error.row),
								importErrors.filter((error) => !error.row)
							)}
						</ul>
					</div>
				)}

				{importMessages.length > 0 && (
					<div className="import-list import-messages">
						<p className="import-list-text">Resultado de la importación</p>
						<ul className="import-rows">
							{this.getImportMessages(importMessages)}
						</ul>
					</div>
				)}
			</Layout>
		);
	}
}

export default CalendarImports;
