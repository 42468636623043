import React from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import { default as modalTypes } from './types';
import './Modal.scss';

const MODAL_TYPES = {
  'alert': modalTypes.alertModal,
  'confirm': modalTypes.confirmModal,
  'delete': modalTypes.deleteModal,
  'edit': modalTypes.editModal,
  'empty': modalTypes.emptyModal
}


class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: props.modalProps.open,
      title: props.modalProps.title || "",
    }
    this.closeModal = this.closeModal.bind(this);
    this.updateTitle = this.updateTitle.bind(this);
  }

  // TODO refactor ¿?
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.modalProps.open !== this.props.modalProps.open) {
      this.setState({
        modalIsOpen: nextProps.modalProps.open,
        title: nextProps.modalProps.title || "",
      })
    }
  }

  closeModal() {
    this.props.hideModal()
  }

  updateTitle(newTitle) {
    this.setState({ title: newTitle });
  }

  render() {
    if (!this.props.modalType) {
      return null
    }
    const SpecifiedModal = MODAL_TYPES[this.props.modalType]

    return (
      <>
        <ReactModal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          overlayClassName="modal fade show"
          bodyOpenClassName="modal-open"
          className="modal-dialog modal-dialog-centered"
          shouldCloseOnOverlayClick={false}
        >
          <SpecifiedModal
            closeModal={this.closeModal}
            updateTitle={this.updateTitle} // Pasamos el método para actualizar el título
            title={this.state.title} // Título dinámico
            {...this.props.modalProps} />
        </ReactModal>
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...state.modalReducer
})


export default connect(mapStateToProps, null)(Modal);