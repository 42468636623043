import React from "react";
import { Link } from "react-router-dom";
import { authService } from "services";
import Logo from "components/Logo";
import { getAppName } from 'utils/utils';

class Restricted extends React.Component {
	componentDidMount() {
		authService.cleanStorage();
	}
	render() {
		const appName = getAppName();

		return (
			<React.Fragment>
				<div className="page-end-trial container-fluid">
					<Link to="/">
						<Logo className="main-logo" type="logo" />
					</Link>
					<h1>Acceso restringido</h1>
					<p>
						El acceso a ciertas funcionalidades o servicios está sujeto a las restricciones del plan contratado. Contacta con <a href="mailto: soporte@haddock.app"> soporte@haddock.app</a> para más información.
					</p>


					<Link className="btn btn-outline-primary" to="/">Volver al inicio</Link>
				</div>
			</React.Fragment>
		);
	}
}

export default Restricted;
